import {Accordion, Col, Container, Row} from 'react-bootstrap';
import '../../assets/content.scss';
import Widget1 from '../Widgets/Widget_1';
import content from "../../config/content";
import {NavLink} from "react-router-dom";
import React from "react";
import faker from '../../img/faker.png'
import faker2 from '../../img/faker2.png'
import faker3 from '../../img/faker3.png'
import fakeInfo from '../../img/fake-info.svg'
import simulatedInfo from '../../img/simulated-info.svg'
import syntheticInfo from '../../img/synthetic-info.svg'
import info21 from '../../img/info21.svg'
import info3 from '../../img/info3.svg'
import info18 from '../../img/info18.svg'
import info20 from '../../img/info20.svg'

function GeneratorFileContent() {
    return (
        <Container fluid className={`container-generator-file`}>
            <Container className={`container-generator-file-item`}>
                <Widget1/>
            </Container>

            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__img-block">
                                <figure>
                                    <img src={faker} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        Random Data Generation Tool for All Your Projects
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        RNDGen Fake Data Generator is the go-to tool for generating test data tailored
                                        to a wide range of needs. The RNDGen data creator uses an existing data model
                                        and customizes it to create a mock data table structure for your needs. Random
                                        Data Generator also known as JSON generator, dummy data generator, CSV
                                        generator, SQL dummy, or mock data generator.

                                        With the ability to customize generated dummy data and select fields from a wide
                                        range of fake details, such as name, email, location, address, ZIP code, VIN,
                                        and many others you can quickly generate thousands of fake data rows in
                                        different formats, including CSV, SQL, JSON, XML, Excel, making RNDGen the
                                        ultimate tool for all your data generation needs instead of standard mock
                                        datasets
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Container className="section-dataset">
                <h2 className="text-center mb-4">
                    Perfect Dummy Data for Basic Testing Completely Free
                </h2>

                <Row className="justify-content-center" style={{ gap: '20px' }}>
                    {
                        [
                            {
                                imag: info21,
                                title: 'Completely Random',
                                text: 'Generates data with no real-world patterns for total randomness'
                            },
                            {
                                imag: info3,
                                title: 'Customizable Output',
                                text: 'Choose from more than 100 different data types, including text, numbers, dates, etc'
                            },
                            {
                                imag: info18,
                                title: 'Complete Data Privacy',
                                text: 'All data is fictitious, ensuring privacy with no real personal info'
                            },
                            {
                                imag: info20,
                                title: 'Unlimited Data Rows',
                                text: 'Generate any amount of data, from small samples to millions of rows'
                            },
                            {
                                imag: info21,
                                title: 'Multi-System Compatibility',
                                text: 'Works seamlessly with various systems for development, testing, and simulations'
                            },
                            {
                                imag: info21,
                                title: 'Export to Multiple Formats',
                                text: 'Export data as CSV, SQL, JSON, XML, or Excel for versatile use'
                            }
                        ].map(({ title, text, imag }, index) => {
                            return (
                                <Col key={index} xs={12} sm={6} md={4}
                                     className="container-generator-file-item text-center mb-4 custom-col">
                                    <img src={imag} alt="Image 1" className="img-fluid mb-3"/>
                                    <h3>{title}</h3>
                                    <p>{text}</p>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>

            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        Create Fields Automatically
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        Skip the frustration of manual field selection and let the AI handle it. Say
                                        goodbye to time-consuming setups and potential human errors, as our AI instantly
                                        creates the necessary fields based on your specific area. This ensures a more
                                        realistic and accurate data structure tailored to your requirements, saving you
                                        valuable time and effort while minimizing costly mistakes
                                    </p>
                                </div>
                            </Col>
                            <Col className="section-dataset__img-block2">
                                <figure>
                                    <img src={faker2} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        Generate Fake Data
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        Quickly generated with no basis in reality, this data is ideal for basic testing
                                        and mock-ups, allowing you to simulate how your application handles inputs
                                        without needing real-world information.
                                    </p>
                                </div>
                            </Col>
                            <Col className="section-dataset__img-block2">
                                <figure>
                                    <img src={fakeInfo} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__img-block">
                                <figure>
                                    <img src={simulatedInfo} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        Generate Simulated Data Modeled on Reality
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        Simulated Data is crafted to imitate real-world scenarios. It’s generated using
                                        an algorithm that mimics real-world data. This data is perfect for situations
                                        where realism is crucial, such as testing user interfaces or predictive models,
                                        ensuring that your systems behave as expected in real-world conditions. Perfect
                                        for realistic testing.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        AI-Generated Real-World Data
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        Synthetic Data is produced using advanced machine learning models to create
                                        datasets that are statistically similar to real data but fully anonymized. This
                                        data is highly accurate and ideal for data analysis, and scenarios where data
                                        privacy is essential. Synthetic Data provides the realism needed for high-stakes
                                        applications without compromising the privacy of real individuals.
                                    </p>
                                </div>
                            </Col>
                            <Col className="section-dataset__img-block2">
                                <figure>
                                    <img src={syntheticInfo} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <Row>
                            <Col className="section-dataset__img-block">
                                <figure>
                                    <img src={faker3} alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title">
                                    <h2>
                                        How to Use Fake Data Generator
                                    </h2>
                                </div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>
                                        To start, specify the number of rows and select the necessary fake data fields
                                        for your mock dataset. Next, customize each field's settings, such as the number
                                        of characters or format, set the order of fields, or disable any that you don't
                                        need. Then, preview the first 10 rows of generated data and choose the desired
                                        file format (CSV, SQL, JSON, XML, or Excel), adjusting any additional settings
                                        like Template Name or Delimiter. Finally, choose how you'd like to download the
                                        fake data file, either as a Zip archive or a single file, and click ‘Download
                                        Data File’ to save your dummy data file.

                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>


            <section className="section-faq">
                <Container>
                    <div className="section-faq__container">
                        <Row>
                            <Col>
                                <div className="section-faq__icon">
                                    <img src="/img/faq.svg" alt={content.home.title} width={221} height={213}/>
                                </div>
                                <div className="section-faq__title">You ask – we answer.</div>
                                <div className="section-faq__description">All you want to know about the product.</div>
                            </Col>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><h2>What is Fake Data?</h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Fake Data is entirely fictional data generated without any connection to
                                            real-world entities. It’s perfect for testing, mock-ups, and simulations
                                            where sensitive information is not required.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><h2>What is the difference between fake data and mock data?
                                        </h2></Accordion.Header>
                                        <Accordion.Body>
                                            Fake data is entirely fictional and randomly generated without any
                                            connection to real-world information. It’s used to simulate data inputs in
                                            testing environments. Mock data, however, is a specific type of fake data
                                            designed for testing purposes, where it’s used to monitor and verify that
                                            certain methods or functions are called the expected number of times or with
                                            specific parameters. This makes mock data particularly useful in unit
                                            testing and other controlled testing scenarios.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><h2>Is the data generated by the Fake Data Generator secure?
                                        </h2></Accordion.Header>
                                        <Accordion.Body>
                                            Yes, all generated data is completely random and does not include any
                                            personal or sensitive information, ensuring total security and privacy.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><h2>Can I customize the data generated?
                                        </h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Absolutely! You can choose from a variety of fields and data types to tailor
                                            the generated data to your specific needs or manually add your own rows.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header><h2>What is dummy data?

                                        </h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Dummy data refers to fake or placeholder data used in a database to simulate
                                            real-world data for testing purposes. It helps developers and testers
                                            evaluate how their systems handle data without the need to use sensitive or
                                            real information.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header><h2>What is a database generator?
                                        </h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            A database generator is a tool that automatically creates and populates
                                            databases with random or simulated data. It is useful for setting up test
                                            environments, running simulations, or creating large datasets for
                                            performance testing.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Container>
    );
}


export default GeneratorFileContent;
